import {createAction, props} from '@ngrx/store';
import {LoadingType} from '@common/_models/core.models';
import {HttpErrorResponse} from '@angular/common/http';
import {TruckLocationModel} from '../../../../../services/broker/model/truckLocationModel';

export const LoadList = createAction(
    '[Load map] Load List',
    props<{ payload: { loading?: LoadingType, clearState?: boolean } }>()
);

export const LoadListSuccess = createAction(
    '[Load map] Load List Success',
    props<{ payload: { items: TruckLocationModel[], clearState?: boolean } }>()
);

export const LoadListFailure = createAction(
    '[Load map] Load List Failure',
    props<{ payload: { error: HttpErrorResponse } }>()
);

export const SetListLoading = createAction(
    '[Load map] Set List Loading',
    props<{ payload: { loading: LoadingType } }>()
);

export const SetInitialState = createAction(
    '[Load map] Set Initial State'
);
