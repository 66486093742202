import * as LoadMapActions from './load-map.actions';
import * as CoreActions from '@common/_store/core.actions';
import {createEntityAdapter} from '@ngrx/entity';
import {TruckLocationModel} from '../../../../../services/broker/model/truckLocationModel';
import {EntityStateModel} from '@common/_models/core.models';
import {createReducer, on} from '@ngrx/store';

export const loadMapFeatureKey = 'loadMap';

export const loadMapAdapter = createEntityAdapter<TruckLocationModel>();

export const initialState: LoadMapState = loadMapAdapter.getInitialState({
    pagination: {},
    listLoading: 'listLoading',
    itemsLoading: []
});

export type LoadMapState = EntityStateModel<TruckLocationModel, any>

export const loadMapReducer = createReducer(
    initialState,

    on(LoadMapActions.LoadList, (state, action) => {
        return {
            ...state,
            listLoading: action.payload.loading
        };
    }),

    on(LoadMapActions.LoadListSuccess, (state, action) => {
        if (action.payload.clearState) {
            return loadMapAdapter.setAll(action.payload.items, {
                ...state,
                listLoading: undefined,
            });
        }

        return loadMapAdapter.upsertMany(action.payload.items, {
            ...state,
            listLoading: undefined,
        });
    }),

    on(LoadMapActions.LoadListFailure, (state) => {
        return {
            ...state,
            listLoading: undefined,
        };
    }),

    on(LoadMapActions.SetListLoading, (state, action) => {
        return {
            ...state,
            listLoading: action.payload.loading
        };
    }),

    on(LoadMapActions.SetInitialState, () => {
        return {
            ...initialState
        };
    }),

    on(CoreActions.LogOutSuccess, () => ({...initialState}))
);
